var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.evaluatee_view && !_vm.evaluator_view)?_c('b-card',{staticClass:"mb-2 ml-0 mr-0",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0 d-flex ml-0 mr-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-1",modifiers:{"accordion-1":true}}],staticClass:"btn-collapse ml-0 mr-0 p-1 noprint",attrs:{"block":"","variant":"info"},on:{"click":function($event){_vm.isAccordionOpen = !_vm.isAccordionOpen}}},[_vm._v(" COEVALUACIÓN "),(_vm.isAccordionOpen)?_c('b-icon',{attrs:{"icon":"caret-down-fill"}}):_c('b-icon',{attrs:{"icon":"caret-up-fill"}})],1)],1),_c('b-collapse',{attrs:{"id":"accordion-1","visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[(
            (!_vm.existEvaluateeTest ||
              _vm.filterEvaluateeRubrics.filter((x) => x.coevaluators.length > 0)
                .length > 0) &&
            _vm.allows_crud
          )?_c('div',{staticClass:"d-flex asd"},[_c('span',{staticClass:"mr-1 align-self-center"},[_c('strong',[_vm._v("Asignar estudiantes de forma Aleatoria:")])]),_c('b-button',{staticClass:"p-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.randomizeCoevaluators()}}},[_vm._v("Asignar")]),(!_vm.existEvaluateeTest)?_c('b-button',{staticClass:"p-1 ml-2",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.unassignCoevaluators()}}},[_vm._v("Limpiar")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"container-body-collapse mt-3"},[_c('div',{staticClass:"table-evaluatees"},[_c('b-table-simple',{attrs:{"bordered":"","hover":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"th-table",staticStyle:{"width":"10%"}},[_vm._v("IDENTIFICADOR")]),_c('b-th',{staticClass:"th-table"},[_vm._v("NOMBRES")]),_c('b-th',{staticClass:"th-table"},[_vm._v("APELLIDOS")]),_c('b-th',{staticClass:"th-table",staticStyle:{"width":"40%"}},[_vm._v("COEVALUAR")])],1)],1),_c('b-tbody',_vm._l((_vm.filterUser),function(evaluatee){return _c('b-tr',{key:evaluatee.id},[_c('b-td',{staticClass:"td-table"},[_vm._v(_vm._s(evaluatee.user_data ? evaluatee.user_data.identifier : ""))]),_c('b-td',{staticClass:"td-table"},[_vm._v(_vm._s(evaluatee.first_name))]),_c('b-td',{staticClass:"td-table"},[_vm._v(_vm._s(evaluatee.last_name))]),_c('b-td',{staticClass:"td-table"},[(_vm.rubric)?_c('CoEvaluatorSelectedInput',{key:_vm.reset_coevaluator_input +
                        '-' +
                        evaluatee.id +
                        _vm.rubric.coevaluation_type,attrs:{"rubric":_vm.rubric,"test":_vm.test,"evaluatee":evaluatee.id,"allows_crud":!_vm.existEvaluateeTest ? _vm.allows_crud : false}}):_vm._e()],1)],1)}),1)],1)],1)])])],1)],1):_vm._e(),(
      (_vm.coevaluateeUsers.length > 0 && _vm.evaluator_view) ||
      (_vm.coevaluatorUsers.length > 0 &&
        _vm.evaluatee_view &&
        _vm.is_running_test &&
        !_vm.finished_test) ||
      (_vm.evaluatee_view &&
        _vm.finished_test &&
        _vm.coevaluateeUsers.length > 0 &&
        _vm.rubric.evaluatee_can_see_coevaluator)
    )?_c('b-table-simple',{staticClass:"table-coevaluator",attrs:{"bordered":"","hover":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"th-coevaluator",staticStyle:{"width":"50%"}},[(_vm.evaluator_view || (_vm.finished_test && _vm.evaluatee_view))?_c('span',[_vm._v("COEVALUADOR"+_vm._s(_vm.coevaluateeUsers.length > 1 ? "ES" : ""))]):(!_vm.finished_test && _vm.is_running_test)?_c('span',[_vm._v("ESTAS COEVALUANDO:")]):_vm._e()])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[((_vm.evaluatee_view && _vm.finished_test) || _vm.evaluator_view)?_c('div',[_vm._v(" "+_vm._s(_vm.coevaluateeUsers .map((x) => { if (x.user_data) return ( x.user_data.identifier + " " + x.first_name + " " + x.last_name ); else return x.first_name + " " + x.last_name; }) .join(", "))+" ")]):(!_vm.finished_test && _vm.is_running_test)?_c('div',[_vm._l((_vm.coevaluatorUsers),function(evaluator,index){return [(_vm.completeCoevaluatorRubric(evaluator.id))?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-success.top.noninteractive",value:('Evaluado'),expression:"'Evaluado'",modifiers:{"v-success":true,"top":true,"noninteractive":true}}],key:evaluator.id + '-' + _vm.test.id,staticClass:"text-success"},[_vm._v(" "+_vm._s(evaluator.user_data ? evaluator.user_data.identifier : "")+" "+_vm._s(evaluator.first_name)+" "+_vm._s(evaluator.last_name)+_vm._s(index + 1 != _vm.coevaluatorUsers.length ? ", " : " ")+" ")]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-danger.top.noninteractive",value:('Falta Evaluar'),expression:"'Falta Evaluar'",modifiers:{"v-danger":true,"top":true,"noninteractive":true}}],key:evaluator.id + '-' + _vm.test.id,staticClass:"text-danger"},[_vm._v(" "+_vm._s(evaluator.user_data ? evaluator.user_data.identifier : "")+" "+_vm._s(evaluator.first_name)+" "+_vm._s(evaluator.last_name)+_vm._s(index + 1 != _vm.coevaluatorUsers.length ? ", " : " ")+" ")])]})],2):_vm._e()])],1)],1)],1):_vm._e(),_c('div')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }